import { mdiAlertOctagram, mdiRefresh, mdiTimerSand } from '@mdi/js'
import { RouteComponentProps } from '@reach/router'
import React, { memo, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { ProductDetailViewUtils } from 'sfportal_views_productdetail_utils/ProductDetailViewUtils'
import { EmptyIndicator } from '../../components/Generic/EmptyIndicator'
import { AppTabs } from '../../components/Layout/AppTabs'
import { DockLayout } from '../../components/Layout/DockLayout'
import { Toolbar } from '../../components/Toolbar/Toolbar'
import { ToolbarButton } from '../../components/Toolbar/ToolbarButton'
import { getFileUriFromEMK } from '../../routes'
import { RequestStatus } from '../../services/api/generic/types'
import { refreshAllData, useProductDetailStore } from '../../stores/productDetailStore'
import './ProductDetailView.scss'
import { useTabs } from './useTabs'
import { saveEntities, Value } from '../../services/api/entityApiService'
import { AssetDialogForm } from '../AssetDialog/AssetDialogForm'
import { Select } from '../../components/Forms/Select'
import { Modal } from '../../components/Generic/Modal'
import { Button } from '../../components/Forms/Button'
import { noop } from '../../utils/function'
import { useUserStore } from '../../stores/userStore'
import { useTranslation } from 'react-i18next'
import { useSubscriptionsForProductView } from './useSubscriptionsForProductView'
import { useAddSubscriptionToArea } from '../../hooks/useAddSubscriptionToArea'
import { Message } from '@stomp/stompjs'
import { apiCompleteTasks } from '../../services/api/tasksApiService'
import { v4 as uuidv4 } from 'uuid'

interface Props extends RouteComponentProps {
}

export interface ProductDetailFile {
  identifier?: string
  filename: string
  entityMetakey?: string
  values?: Value
  sffileformat: FileFormat
  fileformats: FileFormat[]
  noFileSupported: boolean
  fksfdataresources: number
  duplicate: boolean
}

interface FileFormat {
  fksfdataresources: number
  identifier: string
}

interface AutoOpenTask {
  taskId: string
  files: ProductDetailFile[]
  groupedFiles?: ProductDetailFile[]
  filepath: string
  filename?: string

  [key: string]: unknown
}

interface FormatDialogTask {
  task: AutoOpenTask
  format: string
}

export const ProductDetailView = memo<Props>(function ProductDetailView () {
  const { t } = useTranslation()

  const {
    product,
    productStatus,
    taskActionStatus
  } = useProductDetailStore()
  const { currentUser } = useUserStore()
  const {
    visibleTabItems,
    selectedDetailView,
    handleTabChange
  } = useTabs()
  const [isMetadataDialogVisible, setMetadataDialogVisible] = useState<boolean>(false)
  const [isFormatDialogVisible, setFormatDialogVisible] = useState<boolean>(false)
  const [metadataDialogTasks, setMetadataDialogTasks] = useState<Map<string, AutoOpenTask>>(new Map())
  const [formatDialogTasks, setFormatDialogTasks] = useState<Map<string, FormatDialogTask>>(new Map())
  useSubscriptionsForProductView()

  useEffect(() => {
    if (isMetadataDialogVisible) {
      return
    }

    if (metadataDialogTasks.size > 0) {
      // Falls MetadatenDialoge in der Queue sind und der Dialog aktuell nicht sichbar ist wird dieser eingeschalten.
      setMetadataDialogVisible(true)
    }
  }, [isMetadataDialogVisible, metadataDialogTasks.size])

  useEffect(() => {
    if (isFormatDialogVisible) {
      return
    }

    if (formatDialogTasks.size > 0) {
      // Falls FormatDialoge in der Queue sind und der Dialog aktuell nicht sichbar ist wird dieser eingeschalten.
      setFormatDialogVisible(true)
    }
  }, [isFormatDialogVisible, formatDialogTasks.size])

  const userId = useMemo(() => currentUser?.id, [currentUser])

  const addExternalDataToMetadataDialogBody = (externalTaskData: AutoOpenTask, file: ProductDetailFile, index: number): void => {
    if (!file.duplicate && !file.noFileSupported && (file.sffileformat || file.fileformats)) {
      externalTaskData.files[index].sffileformat = file.sffileformat || (file.fileformats ? file.fileformats[0] : null)
      const dataresource = file.fksfdataresources || (file.sffileformat.fksfdataresources)
      externalTaskData.files[index].entityMetakey = file.entityMetakey || `${dataresource}|0`
      externalTaskData.files[index].values = {
        dataresource,
        entity: {
          id: 0,
          identifier: file.filename.replace(/\.[^.]*$/, '').replace(/_/g, ' ')
        }
      }
    }
  }

  const handleExternalTask = useCallback((response: Message): void => {
    const externalTaskData = JSON.parse(response.body)
    if (externalTaskData.dialogKey === 'metadata' || externalTaskData.dialogKey === 'groupedMetadata') {
      externalTaskData.files?.forEach((file: ProductDetailFile, index: number) => {
        addExternalDataToMetadataDialogBody(externalTaskData, file, index)
      })

      externalTaskData.groupedFiles?.forEach((file: ProductDetailFile, index: number) => {
        addExternalDataToMetadataDialogBody(externalTaskData, file, index)
      })

      setMetadataDialogTasks((currentState: Map<string, AutoOpenTask>) => {
        const tempMap = new Map(currentState)
        tempMap.set(uuidv4(), externalTaskData)
        return tempMap
      })
      setMetadataDialogVisible(true)
    } else if (externalTaskData.dialogKey === 'fileformats') {
      const defaultValue = externalTaskData.fileFormatsSelectedDefaultValue as Record<string, string>
      const arrayOfDots = externalTaskData.filename.split('.')
      const fileFormatsSelectedValue = defaultValue !== undefined && arrayOfDots.length > 0 ? defaultValue[arrayOfDots.pop() ?? ''] : ''

      setFormatDialogTasks((currentState: Map<string, FormatDialogTask>) => {
        const tempMap = new Map(currentState)
        tempMap.set(uuidv4(), {
          format: fileFormatsSelectedValue.length > 0 ? fileFormatsSelectedValue : '',
          task: externalTaskData
        })
        return tempMap
      })

      setFormatDialogVisible(true)
    }
  }, [])

  useAddSubscriptionToArea('sfuser', 'workflowTaskAutoOpenExtern', userId?.toString(), handleExternalTask)

  const formatDialog = useMemo(
    () => {
      if (!isFormatDialogVisible || formatDialogTasks.size === 0) {
        return null
      }

      const currentExternalEntry = Array.from<[string, FormatDialogTask]>(formatDialogTasks.entries())[0]
      if (currentExternalEntry === null || currentExternalEntry === undefined) {
        setFormatDialogVisible(false)
        return
      }

      const currentExternalTask = currentExternalEntry[1].task
      const currentFormat = currentExternalEntry[1].format

      const onChangeFormatDialog = (value: string): void => {
        setFormatDialogTasks((currentDialogTasks: Map<string, FormatDialogTask>) => {
          const tempMap = new Map(currentDialogTasks)
          tempMap.set(currentExternalEntry[0], {
            ...currentExternalEntry[1],
            format: value
          })
          return tempMap
        })
      }

      const onCancelFormatDialog = async (): Promise<void> => {
        apiCompleteTasks({
          taskId: currentExternalTask.taskId,
          formFields: { cancelUpload: true }
        }).catch(noop)

        setFormatDialogTasks((currentState: Map<string, FormatDialogTask>) => {
          const tempMap = new Map(currentState)
          tempMap.delete(currentExternalEntry[0])
          return tempMap
        })

        setFormatDialogVisible(false)
      }

      const onAcceptFormatDialog = async (): Promise<void> => {
        const newFiles = currentExternalTask.files.map(item => {
          let fileformat = item.fileformats[0]
          if (currentFormat !== '') {
            fileformat = item.fileformats.find(fileformat => fileformat.identifier === currentFormat) as FileFormat
          }

          item.sffileformat = fileformat
          return item
        })

        apiCompleteTasks({
          taskId: currentExternalTask.taskId,
          formFields: {
            cancelUpload: false,
            files: newFiles,
            groupedFiles: currentExternalTask.groupedFiles
          }
        }).catch(noop)

        setFormatDialogTasks((currentState: Map<string, FormatDialogTask>) => {
          const tempMap = new Map(currentState)
          tempMap.delete(currentExternalEntry[0])
          return tempMap
        })

        setFormatDialogVisible(false)
      }

      return isFormatDialogVisible ? <FormatDialog
        isFormatDialogVisible={isFormatDialogVisible}
        title={currentExternalTask.filename}
        externalTask={currentExternalTask}
        value={currentFormat}
        onChange={onChangeFormatDialog}
        onCancelDialog={onCancelFormatDialog}
        onAcceptDialog={onAcceptFormatDialog} /> : null
    }, [formatDialogTasks, isFormatDialogVisible]
  )

  const metadataDialog = useMemo(
    () => {
      if (!isMetadataDialogVisible || metadataDialogTasks.size === 0) {
        return null
      }

      const currentExternalEntry = Array.from<[string, AutoOpenTask]>(metadataDialogTasks.entries())[0]
      if (currentExternalEntry === null || currentExternalEntry === undefined) {
        setMetadataDialogVisible(false)
        return
      }

      const currentExternalTask = currentExternalEntry[1]

      const cancelMetadataDialog = async (): Promise<void> => {
        await apiCompleteTasks({
          taskId: currentExternalTask.taskId,
          formFields: { cancelUpload: true }
        }).catch(noop)

        setMetadataDialogTasks((currentState: Map<string, AutoOpenTask>) => {
          const tempMap = new Map(currentState)
          tempMap.delete(currentExternalEntry[0])
          return tempMap
        })

        setMetadataDialogVisible(false)
      }

      const saveMetadataDialog = async (values: Value[]): Promise<void> => {
        const response = await saveEntities(values)
        const files = currentExternalTask.groupedFiles || currentExternalTask.files
        const savedValues: Value[] = response.body ?? []

        for (const file of files) {
          const value = savedValues.find(_value => _value.entity.identifier === file.values?.entity.identifier)
          if (value !== null && value !== undefined) {
            file.entityMetakey = value.dataresource + '|' + value.entity.id
          }
        }

        apiCompleteTasks({
          taskId: currentExternalTask.taskId,
          formFields: {
            files,
            cancelUpload: false,
            isExistsMetadata: true
          }
        }).catch(noop)

        setMetadataDialogTasks((currentState: Map<string, AutoOpenTask>) => {
          const tempMap = new Map(currentState)
          tempMap.delete(currentExternalEntry[0])
          return tempMap
        })

        setMetadataDialogVisible(false)
      }

      return (
        isMetadataDialogVisible ? <AssetDialogForm
          title={currentExternalTask.filename}
          formTypes={['typeForNew', 'type']}
          onSave={saveMetadataDialog}
          entityMetakeys={currentExternalTask?.files.map((file: any) => file.entityMetakey)}
          overrideValues={currentExternalTask?.files.map((file: any) => file.values)}
          onCancel={cancelMetadataDialog} /> : <></>
      )
    },
    [isMetadataDialogVisible, metadataDialogTasks]
  )

  const coverImage = useMemo<ReactElement>(() => {
    if (product === null || product.cover === null) {
      return <div className={'product-detail-view__image'}></div>
    }
    const src = getFileUriFromEMK(product.cover)
    if (src === null) {
      return <div className={'product-detail-view__image'}></div>
    }
    return <img src={src} alt="" className={'product-detail-view__image'} />
  }, [product])

  return <DockLayout
    className="product-detail-view"
    header={
      <Toolbar>
        <ToolbarButton
          icon={mdiRefresh}
          label={t('common.refresh')}
          disabled={
            productStatus === RequestStatus.pending ||
            taskActionStatus === RequestStatus.pending
          }
          onClick={() => refreshAllData()}
        />
      </Toolbar>
    }
    center={() => {
      if (productStatus === RequestStatus.pending) {
        return (
          <EmptyIndicator
            text={t('productDetailView.loading')}
            icon={mdiTimerSand}
          />
        )
      }

      if (product === null) {
        return (
          <EmptyIndicator
            text={t('productDetailView.notFound')}
            icon={mdiAlertOctagram}
          />
        )
      }

      return (
        <div className="product-detail-view__content">
          <header className="product-detail-view__header">
            {product.cover !== null ? (
              <div className="product-detail-view__image-container">
                {coverImage}
              </div>
            ) : null}

            <div className="product-detail-view__detail-container">
              <div className="product-detail-view__detail-line">
                <div className="product-detail-view__title">
                  {product.description}
                </div>
              </div>
              <div className="product-detail-view__detail-line">
                <div className="product-detail-view__properties">
                  <ProductDetailViewUtils.MetadataDetailsRender
                    product={product}
                  />
                  <ProductDetailViewUtils.TeamsRender
                    product={product}
                  />
                </div>
              </div>
            </div>
          </header>
          <div className="product-detail-view__body">
            <AppTabs onChange={handleTabChange} items={visibleTabItems} />
          </div>
          {metadataDialog}
          {formatDialog}
        </div>
      )
    }}
    right={selectedDetailView}
  />
})

interface FormatDialogProps {
  isFormatDialogVisible: boolean
  title?: string
  externalTask?: AutoOpenTask
  value: string
  onChange: (value: string) => void
  onCancelDialog: () => void
  onAcceptDialog: () => void
}

export const FormatDialog = ({
  isFormatDialogVisible,
  title,
  externalTask,
  value,
  onChange,
  onCancelDialog,
  onAcceptDialog
}: FormatDialogProps) => {
  const { t } = useTranslation()

  if (!isFormatDialogVisible || externalTask === null || externalTask === undefined) {
    return null
  }

  const fileFormats: Record<string, string> = {}
  externalTask.files.forEach((file: ProductDetailFile) => {
    if (file.fileformats !== null) {
      file.fileformats.forEach((fileformat: FileFormat) => {
        const dataresource = fileformat.identifier
        fileFormats[dataresource] = t(`global.dataresource.sm${fileformat.identifier}.singular`)
      })
    }
  })
  const footer = (): React.ReactElement => {
    return (
      <div className="buttons">
        <Button buttonStyle="secondary" onClick={onCancelDialog}>
          {t('common.cancel')}
        </Button>
        <Button buttonStyle="primary" onClick={onAcceptDialog}>
          {t('common.done')}
        </Button>
      </div>
    )
  }

  return <Modal
    title={title}
    visible={isFormatDialogVisible}
    width="small"
    padding="small"
    verticalAlign="center"
    footer={footer()}>
    <Select
      value={value}
      name={'formatType'}
      onChange={onChange}
      items={fileFormats}
      label={'Type'}
    />
  </Modal>
}
