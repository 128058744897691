import { mdiBook } from '@mdi/js'
import React, { memo, useMemo } from 'react'
import { ChildrenProp } from '../../jsx'
import { ApiTask, ApiTaskDataResourceProduct } from '../../services/api/apiSchemas'
import { useProductDetailStore } from '../../stores/productDetailStore'
import { ListItemTask } from './ListItemTask'
import { useTaskActions } from './useTaskActions'

interface Props extends ChildrenProp {
  task: ApiTask
  showTaskName?: boolean
  highlight?: boolean
}

export const ListItemTaskProduct = memo(function ListItemTaskProduct ({
  task,
  highlight,
  showTaskName = false
}: Props) {
  const { currentFile } = useProductDetailStore()

  const taskActions = useTaskActions({ task })

  const dataResource = useMemo(
    () => task.dataResource as ApiTaskDataResourceProduct,
    [task.dataResource]
  )


  return <>
    {taskActions.taskModalElement}

    <ListItemTask
      listItemType={'product'}
      icon={mdiBook}
      pubtargetIdentifier={task.pubtargetIdentifier}
      title={dataResource.name}
      titleSuffix={showTaskName ? task.name : undefined}
      highlight={highlight}
      current={currentFile === dataResource}
      actions={<>
        {taskActions.taskActionElements}
      </>}
    />
  </>
})
