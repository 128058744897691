import React, { memo, ReactNode } from 'react'
import { ApiTask } from '../../services/api/apiSchemas'
import { ListItemGroup } from './ListItemGroup'
import { ListItemTaskAsset } from './ListItemTaskAsset'
import { ListItemTaskEditorDocument } from './ListItemTaskEditorDocument'
import { ListItemTaskProduct } from './ListItemTaskProduct'
import { ListItemTaskSmashdocs } from './ListItemTaskSmashdocs'
import { ListItemTaskUnknown } from './ListItemTaskUnknown'
import { ListItemGroupTaskUtils } from 'sfportal_components_listitem_utils_listitemgroup/ListItemGroupTaskUtils'

interface Props {
  title: string
  titleSuffix?: string
  highlightGroup?: boolean
  highlightTasks?: Array<ApiTask['dataResource']['id']>
  tasks: ApiTask[]
  isExpanded: boolean
  onIsExpandedChange?: () => void
}

export const ListItemGroupTasks = memo(function ListItemGroupTasks ({
  title,
  titleSuffix,
  tasks,
  isExpanded,
  highlightGroup,
  highlightTasks,
  onIsExpandedChange
}: Props) {
  return (
    <ListItemGroup
      title={title}
      titleSuffix={titleSuffix}
      isOpen={isExpanded}
      highlight={highlightGroup}
      onIsOpenChange={onIsExpandedChange}
      actions={
        <>
          <ListItemGroupTaskUtils.GroupHeaderDropdown
            tasks={tasks}
            title={title}
          />
        </>
      }
    >
      {tasks.map((task): ReactNode => {
        switch (task.dataResource.type) {
          case 'asset':
            return <ListItemTaskAsset key={task.id} task={task} />

          case 'smashdocs':
            return (
              <ListItemTaskSmashdocs
                key={task.id}
                task={task}
                highlight={highlightTasks?.includes(task.dataResource.uuid)}
              />
            )

          case 'product':
            return (
              <ListItemTaskProduct
                key={task.id}
                task={task}
                highlight={highlightTasks?.includes(task.dataResource.id)}
              />
            )

          case 'editordocument':
            return (
              <ListItemTaskEditorDocument
                key={task.id}
                task={task}
                highlight={highlightTasks?.includes(task.dataResource.id)}
              />
            )

          default:
            return <ListItemTaskUnknown key={task.id} task={task} />
        }
      })}
    </ListItemGroup>
  )
})
