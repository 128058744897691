import { RequestStatus } from '../services/api/generic/types'
import { TFunction } from 'i18next'

export interface RequestStatusData {
  id: RequestStatus
  shortText: string
  longText: string
}

// Bei TypeScript-Fehler:
// Function lacks ending return statement and return type
// does not include 'undefined'. ts(2366)
// -> Es wurden neue Werte in `RequestStatus` definiert und wurden hier
// noch nicht behandelt. Restliche cases einfügen!
export function getRequestStatusData (id: RequestStatus, t: TFunction): RequestStatusData {
  switch (id) {
    case RequestStatus.pending: return {
      id,
      shortText: t('request.status.pending.short'),
      longText: t('request.status.pending.long')
    }
    case RequestStatus.ok: return {
      id,
      shortText: t('request.status.ok.short'),
      longText: t('request.status.ok.long')
    }
    case RequestStatus.networkError: return {
      id,
      shortText: t('request.status.networkError.short'),
      longText: t('request.status.networkError.long')
    }
    case RequestStatus.unknownError: return {
      id,
      shortText: t('request.status.unknownError.short'),
      longText: t('request.status.unknownError.long')
    }
    case RequestStatus.badRequest:
    case RequestStatus.unknownRequestError: return {
      id,
      shortText: t('request.status.badRequest.short'),
      longText: t('request.status.badRequest.long')
    }
    case RequestStatus.unauthorizedError: return {
      id,
      shortText: t('request.status.unauthorizedError.short'),
      longText: t('request.status.unauthorizedError.long')
    }
    case RequestStatus.forbiddenError: return {
      id,
      shortText: t('request.status.forbiddenError.short'),
      longText: t('request.status.forbiddenError.long')
    }
    case RequestStatus.notFoundError: return {
      id,
      shortText: t('request.status.notFoundError.short'),
      longText: t('request.status.notFoundError.long')
    }
    case RequestStatus.serverError: return {
      id,
      shortText: t('request.status.serverError.short'),
      longText: t('request.status.serverError.long')
    }
  }
}
