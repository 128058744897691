import { mdiMenuDown, mdiMenuRight } from '@mdi/js'
import React, { memo, useCallback, useMemo } from 'react'
import { ChildrenProp } from '../../jsx'
import classNames from 'classnames'
import { MdiIcon } from '../Generic/MdiIcon'
import './ListItemGroup.scss'

interface Props extends ChildrenProp {
  title: string
  titleSuffix?: string
  actions?: JSX.Element | null
  isOpen: boolean
  highlight?: boolean
  onIsOpenChange?: () => void
}

export const ListItemGroup = memo(function ListItemGroup ({
  children, title, titleSuffix, isOpen,
  highlight = false,
  actions = null,
  onIsOpenChange
}: Props) {
  const openIndicatorIcon = useMemo(
    () => isOpen ? mdiMenuDown : mdiMenuRight,
    [isOpen]
  )

  const listItemGroupClasses = useMemo(
    () => classNames(
      'list-item-group',
      { 'list-item-group--is-highlighted': highlight },
      { 'list-item-group--is-clickable': onIsOpenChange !== undefined },
      { 'list-item-group--is-open': isOpen }
    ),
    [highlight, isOpen, onIsOpenChange]
  )

  const handleHeaderClick = useCallback(
    () => { onIsOpenChange?.() },
    [onIsOpenChange]
  )

  return (
    <div className={listItemGroupClasses}>
      <div className="list-item-group__header">
        <div
          className="list-item-group__open-indicator-container"
          onMouseDown={handleHeaderClick}
        >
          <MdiIcon path={openIndicatorIcon} />
        </div>

        <div className="list-item-group__title" onMouseDown={handleHeaderClick}>
          {title}
          {titleSuffix !== undefined ? (
            <span className="list-item-group__title-suffix">
              {titleSuffix}
            </span>
          ) : null}
        </div>

        {actions !== null ? (
          <div className="list-item-group__actions">{actions}</div>
        ) : null}
      </div>

      <div className="list-item-group__body">{children}</div>
    </div>
  )
})
