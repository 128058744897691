import { mdiCloseCircle } from '@mdi/js'
import React, { memo, SyntheticEvent } from 'react'
import { RequestStatus } from '../../services/api/generic/types'
import { noop } from '../../utils/function'
import { LoadingContainer } from '../Layout/LoadingContainer'
import { EmptyIndicator } from './EmptyIndicator'
import './FilePreview.scss'
import { useTranslation } from 'react-i18next'

interface Props {
  path: string
  loading?: boolean
}

export const FilePreview = memo(function FilePreview ({
  path,
  loading = false
}: Props) {
  const { t } = useTranslation()

  /**
   * Verändert den Content des Iframes nachdem dieser geladen wurde.
   * Das wird benötigt um zum Beispiel styles im iframe dokument zu verändern,
   * da css files den iframe content nicht verändern.
   *
   * @param event Das on Load Event des Iframes.
   */
  const handleIFrameLoad = (
    event: SyntheticEvent<HTMLIFrameElement, Event>
  ): void => {
    const { contentDocument } = event.currentTarget
    if (contentDocument === null) return

    const styles: Partial<CSSStyleDeclaration> = {
      margin: '30px'
    }

    Object.assign(contentDocument.body.style, styles)
  }

  return (
    <LoadingContainer
      loadingText={t('generic.filePreview.loading')}
      status={loading ? RequestStatus.pending : RequestStatus.ok}
      onRetry={noop}
    >
      {path === null || path === '' || path === '/api/files/' ? (
        <EmptyIndicator
          compact={true}
          stretch={true}
          dimmed={true}
          icon={mdiCloseCircle}
          text={t('generic.filePreview.empty')}
        />
      ) : (
        <div className="file-preview">
          <iframe
            src={path}
            onLoad={handleIFrameLoad}
            title="preview"
            className="file-preview__iframe"
          />
        </div>
      )}
    </LoadingContainer>
  )
})
