import React, { FC, useContext, useEffect, useState } from 'react'
import { Subviews } from 'sfportal_components_generic/Subviews'
import { apiGetDataresourceByIdentifier } from 'sfportal_services_api/dataresourceApiService'
import {
  loadDataresourceForNodeId,
  setCurrentItem,
  toggleNodeOpenState
} from 'sfportal_stores/productTreeStore'
import { noop } from 'sfportal_utils/function'
import { useIsMounted } from 'sfportal_utils/useIsMounted'
import { useGetProjectProductTreeListSubviews } from 'sfportal_views_productdetail_producttree_list/useGetProjectProductTreeListSubviews'
import { Tree } from '../../../../components/Tree/Tree'
import { ChildrenProp } from '../../../../jsx'
import { useProductDetailStore } from '../../../../stores/productDetailStore'
import { ProductTreeSubviewsContext } from './useProductTreeContext'
import { useProductTreeData } from './useProductTreeData'

interface Props extends ChildrenProp {}

export const ProductTree: FC<Props> = () => {
  const isMounted = useIsMounted()

  const { currentListSubview: currentSubview } = useContext(
    ProductTreeSubviewsContext
  )
  const { items, openNodes, itemActions, selectedItem } = useProductTreeData()
  const getProjectProductTreeListSubviews =
    useGetProjectProductTreeListSubviews()
  const { product } = useProductDetailStore()

  const [
    smtreenodeContentsDataresourceId,
    setSmtreenodeContentsDataresourceId
  ] = useState<number | null>(null)

  useEffect(() => {
    apiGetDataresourceByIdentifier('smtreenodecontents').then(result => {
      if (!isMounted()) return
      setSmtreenodeContentsDataresourceId(result.body.id)
    }).catch(noop)
  }, [isMounted])

  useEffect(() => {
    if (selectedItem === null) return
    const selectedTreeNode = items.find(item => item.id === selectedItem)
    if (selectedTreeNode === undefined) return
    if (selectedTreeNode.disabled === true) {
      setCurrentItem(null).catch(noop)
    }
  }, [items, selectedItem])

  async function handleItemClick (index: number): Promise<void> {
    if (smtreenodeContentsDataresourceId === null) return
    const node = items[index]
    if (
      node.item.entityMetaKey.startsWith(
        smtreenodeContentsDataresourceId.toString()
      )
    ) {
      return
    }
    if (product === null) return
    await loadDataresourceForNodeId(product.id, node.id)
    if (!isMounted()) return
    setCurrentItem(node.id).catch(noop)
  }

  function handleItemToggle (index: number, isOpen: boolean): void {
    toggleNodeOpenState(Object.values(items)[index].id, isOpen)
  }

  return (
    <div className="product-tree">
      <Subviews
        views={getProjectProductTreeListSubviews()}
        current={currentSubview}
      />

      {currentSubview === null ? (
        <Tree
          items={items}
          openNodes={openNodes}
          renderLabel={(node) => node.item.name}
          isItemClickable={(index) =>
            smtreenodeContentsDataresourceId !== null &&
            !items[index].item.entityMetaKey.startsWith(
              smtreenodeContentsDataresourceId.toString()
            )
          }
          onItemClick={handleItemClick}
          onItemToggle={handleItemToggle}
          itemActions={itemActions}
          selectedItemId={selectedItem}
        />
      ) : null}
    </div>
  )
}
