import { Response } from 'superagent'
import { ApiProduct, ApiTask, ApiTaskDataResourceEditorDocument, EMK } from './apiSchemas'
import { sendRequest } from './superagent'

/**
 * Interface für die Filteung von Tasks.
 */
export interface TaskFilter {
  myTask: boolean
  openTask: boolean
  candidateTasks: boolean
  substitutionTask: boolean
  workflowTask: string
  filterObj: string
}

/**
 * Default Filter, falls keiner gegeben ist.
 */
const defaultFilter: TaskFilter = {
  myTask: true,
  openTask: true,
  candidateTasks: process.env.REACT_APP_CANDIDATE_MAPPING_DEFAULT === 'true',
  substitutionTask: false,
  workflowTask: '',
  filterObj: ''
}

export async function apiGetTasks (
  productId: ApiProduct['id'],
  start: number,
  count: number,
  context: () => unknown,
  filter?: TaskFilter,
  sort?: string
): Promise<Response | null> {
  const startingContext = context()
  const query = {
    productId: productId.toString(),
    sort: sort === undefined ? '' : sort,
    start: start.toString(),
    count: count.toString(),
    filter: JSON.stringify(filter ?? defaultFilter)
  }

  const result = await sendRequest('get', 'tasks', { query })
  const endingContext = context()
  if (startingContext !== endingContext) {
    return null
  }
  return result
}

/**
 * Liest Tasks nach ihrer ID aus.
 *
 * @param productId Die ProduktId.
 * @param body Array mit den TaskIds.
 * @param context Der Context, in dem der Request ausgeführt wurde.
 * @param filter Der Filter gegen den die Tasks geprüft werden.
 */
export async function apiPostTasks (
  productId: ApiProduct['id'],
  body: Array<ApiTask['id']>,
  context: () => unknown,
  filter?: TaskFilter
): Promise<Response | null> {
  const startingContext = context()
  const query = {
    productId: productId.toString(),
    filter: JSON.stringify(filter ?? defaultFilter)
  }
  const result = await sendRequest('post', 'tasks', { query, body })
  const endingContext = context()
  if (startingContext !== endingContext) {
    return null
  }
  return result
}

export async function apiClaimTasks (
  ...taskId: Array<ApiTask['id']>
): Promise<Response> {
  const query = { taskId }
  return await sendRequest('post', 'tasks/claim', { query })
}

export async function apiUnclaimTasks (
  ...taskId: Array<ApiTask['id']>
): Promise<Response> {
  const query = { taskId }
  return await sendRequest('post', 'tasks/unclaim', { query })
}

export async function apiCompleteTasks (
  ...tasks: Array<{
    taskId: ApiTask['id']
    formFields?: Record<string, unknown>
  }>
): Promise<Response> {
  const body = tasks
  return await sendRequest('post', 'tasks/complete', { body })
}

interface ApiSendTaskCommentParams {
  taskId: ApiTask['id']
  comment: string
}

export async function apiSendTaskComment ({
  taskId, comment
}: ApiSendTaskCommentParams): Promise<Response> {
  const body = { taskId, comment }
  return await sendRequest('post', 'tasks/comment', { body })
}

export async function apiGetTaskformfields (taskId: ApiTask['id']): Promise<Response> {
  return await sendRequest('get', `tasks/formfields/${taskId}`)
}

export async function apiGetTaskAssetEditorUrl (taskId: ApiTask['id'], emk?: ApiTaskDataResourceEditorDocument['id']): Promise<Response> {
  return await sendRequest('get', `tasks/editorUrl/${taskId}/${emk ?? ''}`)
}

export async function apiGetCorrectionPdf (assetEMK: EMK): Promise<Response> {
  return await sendRequest('get', 'tasks/correction', { query: { id: assetEMK } })
}
