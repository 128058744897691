import { mdiFastForward, mdiPlay, mdiStop } from '@mdi/js'

/**
 * Icon, welches als Claimtask angezeigt wird.
 */
export const CLAIM_TASK_ICON = mdiPlay

/**
 * Icon, welches fürs Task zurückgeben angezeigt wird.
 */
export const STOP_TASK_ICON = mdiStop

/**
 * Icon, welches fürs abschließen von Task angezeigt wird.
 */
export const COMPLETE_TASK_ICON = mdiFastForward
