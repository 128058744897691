import { mdiFileOutline } from '@mdi/js'
import React, { memo } from 'react'
import { ChildrenProp } from '../../jsx'
import { ApiTask } from '../../services/api/apiSchemas'
import { useProductDetailStore } from '../../stores/productDetailStore'
import { ListItemTask } from './ListItemTask'
import { useTaskActions } from './useTaskActions'

interface Props extends ChildrenProp {
  task: ApiTask
  showTaskName?: boolean
  highlight?: boolean
}

export const ListItemTaskUnknown = memo(function ListItemTaskUnknown ({
  task, highlight,
  showTaskName = false
}: Props) {
  const { currentFile } = useProductDetailStore()
  const taskActions = useTaskActions({ task })

  return <>
    {taskActions.taskModalElement}

    <ListItemTask
      listItemType="unknown"
      icon={mdiFileOutline}
      title={task.dataResource.name}
      titleSuffix={showTaskName ? task.name : undefined}
      highlight={highlight}
      current={currentFile === task.dataResource}
      dueDate={task.dueDate}
      pubtargetIdentifier={task.pubtargetIdentifier}
      actions={<>
        {taskActions.taskActionElements}
      </>}
    />
  </>
})
