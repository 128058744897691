import { createReactHook, createStore } from '@alinnert/tstate'
import { GenericValue } from 'sfportal_utils/utility-types'
import { toFlatTreeNode } from '../components/Tree/toFlatTreeNode'
import { FlatTreeNode } from '../components/Tree/Tree'
import {
  ApiExternalMetadata,
  ApiProduct,
  ApiTreeNode,
  ApiTreeNodeRoot
} from '../services/api/apiSchemas'
import { fetchFileMetadata } from '../services/api/filesApiService'
import { RequestStatus } from '../services/api/generic/types'
import {
  fetchProductTree,
  getTreeNodeDataresource
} from '../services/api/productsApiService'
import { arrayWithout } from '../utils/array'
import { noop } from '../utils/function'
import { handleRequestError } from './allStores'
import { getCurrentProduct } from './productDetailStore'
import { TreeState } from './TreeState'

/**
 * @file Dieser Store verwaltet alle Daten des Produktbaums
 */

// #region store
export interface ProductTreeStore extends TreeState<FlatTreeNode<ApiTreeNode>> {
  /** Ladestatus des Produktbaums. */
  treeStatus: RequestStatus

  /** ID (nicht EMK) des ausgewählten Baumknotens. */
  selectedItem: string | null
  /** Metadaten des ausgewählten Baumknotens. */
  selectedItemMetadata: ApiExternalMetadata | null
  /** Ladestatus der Metadaten für den ausgewählten Baumknotens. */
  selectedItemMetadataStatus: RequestStatus
}

function getInitialState (): ProductTreeStore {
  return {
    treeStatus: RequestStatus.ok,
    nodes: {},
    relations: {},
    openNodeIds: [],
    selectedItem: null,
    selectedItemMetadata: null,
    selectedItemMetadataStatus: RequestStatus.ok
  }
}

const store = createStore(getInitialState())
export const useProductTreeStore = createReactHook(store)

const mutations = {
  reset (): void {
    store.set(getInitialState())
  },

  resetCurrentItem (): void {
    const { selectedItem, selectedItemMetadata, selectedItemMetadataStatus } =
      getInitialState()

    store.set({
      selectedItem,
      selectedItemMetadata,
      selectedItemMetadataStatus
    })
  },

  setTreeStatus (treeStatus: ProductTreeStore['treeStatus']): void {
    store.set({ treeStatus })
  },

  setTree (
    nodes: ProductTreeStore['nodes'],
    relations: ProductTreeStore['relations']
  ): void {
    store.set({ nodes, relations, treeStatus: RequestStatus.ok })
  },

  toggleNode (nodeId: GenericValue<ProductTreeStore['openNodeIds']>): void {
    const open = store.state.openNodeIds.includes(nodeId)
    mutations.openNode(nodeId, open)
  },

  openNode (
    nodeId: GenericValue<ProductTreeStore['openNodeIds']>,
    open: boolean
  ): void {
    if (open) {
      store.set({ openNodeIds: [...store.state.openNodeIds, nodeId] })
    } else {
      store.set({ openNodeIds: arrayWithout(store.state.openNodeIds, nodeId) })
    }
  },

  setCurrentItem (currentItem: ProductTreeStore['selectedItem']): void {
    if (currentItem === null) {
      mutations.resetCurrentItem()
    } else {
      store.set({ selectedItem: currentItem })
    }
  },

  setDataresourceForNodeId (
    nodeId: string,
    dataResource: ApiTreeNode['dataResource']
  ): void {
    const newItem = store.state.nodes[nodeId]
    newItem.item.dataResource = dataResource
    store.set({
      nodes: {
        ...store.state.nodes,
        [nodeId]: newItem
      }
    })
  },

  setCurrentItemMetadata (
    currentItemMetadata: ProductTreeStore['selectedItemMetadata']
  ): void {
    store.set({
      selectedItemMetadata: currentItemMetadata,
      selectedItemMetadataStatus: RequestStatus.ok
    })
  },

  setCurrentItemMetadataStatus (currentItemMetadataStatus: RequestStatus): void {
    store.set({ selectedItemMetadataStatus: currentItemMetadataStatus })
  }
}
// #endregion store

// #region actions
export function resetProductTreeStore (): void {
  mutations.reset()
}

export async function loadProductTree (): Promise<void> {
  const id = getCurrentProduct()?.id ?? null
  if (id === null) return

  mutations.setTreeStatus(RequestStatus.pending)

  try {
    const result = await fetchProductTree(
      id,
      () => getCurrentProduct()?.id ?? null
    )
    if (result === null) return
    const treeData = result.body as ApiTreeNodeRoot
    if (treeData.treenodes === null) return

    const { nodes, relations } = parseProductTreeFromServer(treeData.treenodes)
    mutations.setTree(nodes, relations)
  } catch (error) {
    mutations.setTreeStatus(handleRequestError(error))
  }
}

export function toggleNodeOpenState (
  id: GenericValue<ProductTreeStore['openNodeIds']>,
  open?: boolean
): void {
  if (open !== undefined) {
    mutations.openNode(id, open)
  } else {
    mutations.toggleNode(id)
  }
}

export function toggleNodeOpenStateByIndex (
  index: number,
  isOpen?: boolean
): void {
  const treeNode = Object.values(store.state.nodes)[index]
  if (treeNode === undefined) return

  const node = Object.values(store.state.nodes)[index]
  toggleNodeOpenState(node.id, isOpen)
}

export async function setCurrentItem (
  item: ProductTreeStore['selectedItem']
): Promise<void> {
  mutations.setCurrentItem(item)
  if (item === null) return

  loadCurrentItemMetadata().catch(noop)
}

export function getTreeNodeById (
  id: keyof ProductTreeStore['nodes']
): FlatTreeNode<ApiTreeNode> | null {
  return store.state.nodes[id] ?? null
}
// #endregion actions

// #region functions
export async function loadCurrentItemMetadata (): Promise<void> {
  const itemId = store.state.selectedItem
  if (itemId === null) return
  const selectedItem = store.state.nodes[itemId]
  if (selectedItem === undefined) return

  mutations.setCurrentItemMetadataStatus(RequestStatus.pending)

  try {
    const result = await fetchFileMetadata(
      selectedItem.item.entityMetaKey,
      () => store.state.selectedItem
    )
    if (result === null) return

    const metadata = result.body as ProductTreeStore['selectedItemMetadata']
    mutations.setCurrentItemMetadata(metadata)
  } catch (error) {
    mutations.setCurrentItemMetadataStatus(handleRequestError(error))
  }
}

/**
 * Parst den gesamten Baum, der von der API zurückkommt und wandelt ihn in die
 * flache Struktur um, der vom Store gefordert wird.
 */
function parseProductTreeFromServer (
  apiNodes: ApiTreeNode[]
): Pick<ProductTreeStore, 'nodes' | 'relations'> {
  const nodes: ProductTreeStore['nodes'] = {}
  const relations: ProductTreeStore['relations'] = {}

  parseNodes({
    id: 'root',
    currentNodes: apiNodes,
    level: 0,
    path: []
  })

  interface ParseNodesParams {
    id: keyof ProductTreeStore['relations']
    currentNodes: ApiTreeNode[]
    level: number
    path: string[]
  }
  function parseNodes ({
    id,
    currentNodes,
    level,
    path
  }: ParseNodesParams): void {
    const children = currentNodes.map((node) =>
      toFlatTreeNode({
        id: node.id.toString(),
        item: node,
        level,
        path,
        getChildren: (item) => item.treenodes ?? [],
        recursiveCallback: parseNodes
      })
    )
    relations[id] = []

    for (const child of children) {
      nodes[child.id] = child
      relations[id]?.push(child.id)
    }
  }

  return { nodes, relations }
}

export async function loadDataresourceForNodeId (
  productId: ApiProduct['id'],
  id: string
): Promise<void> {
  const dataresource = await getTreeNodeDataresource(
    productId,
    store.state.nodes[id].item.entityMetaKey
  )
  mutations.setDataresourceForNodeId(id, JSON.parse(dataresource.text))
}
// #endregion functions
