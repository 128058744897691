import React, { memo, useCallback, useMemo } from 'react'
import { ZoomFadeDiv } from '../../components/Animations/ZoomFadeDiv'
import { Button } from '../../components/Forms/Button'
import { CenteredLayer } from '../../components/Layout/CenteredLayer'
import { ChildrenProp } from '../../jsx'
import { LoginViewScreen, setCurrentLoginViewScreen, useUserStore } from '../../stores/userStore'
import { useTranslation } from 'react-i18next'

interface Props extends ChildrenProp {}

export const ConfirmScreen = memo(function ConfirmScreen (props: Props) {
  const { currentLoginViewScreen } = useUserStore()

  const { t } = useTranslation()

  const isCurrentScreen = useMemo(
    () => currentLoginViewScreen === LoginViewScreen.confirm,
    [currentLoginViewScreen]
  )

  const handleToLoginClick = useCallback(
    () => { setCurrentLoginViewScreen(LoginViewScreen.login) },
    []
  )

  return (
    <ZoomFadeDiv
      className="login-view__view-item"
      visible={isCurrentScreen}
    >
      <CenteredLayer>
        <h1 className="login-view__title">{t('login.confirmScreen.title')}</h1>

        <p className="login-view__text">
          {t('login.confirmScreen.content')}
        </p>

        <div className="login-view__buttons">
          <Button
            type="button"
            buttonStyle="primary"
            onClick={handleToLoginClick}
          >Zum Login</Button>
        </div>
      </CenteredLayer>
    </ZoomFadeDiv>
  )
})
