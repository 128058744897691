/**
 * Eine Liste der SiteFusion-Datei-Typen, die im Browser direkt dargestellt
 * werden können.
 */
export const viewableImages = [
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg',
  '7'
]

/**
 * Eine Liste der SiteFusion-Datei-Typen, für die nicht der Vorschaupfad
 * verwendet werden soll, sondern das Asset selbst, um es im Browser
 * anzuzeigen.
 */
export const typesToShowDirectly = [
  'file/pdf',
  'image/pdf',
  'image/bmp',
  'image/jpeg',
  'image/gif',
  'image/png',
  'image/svg',
  '393', // PDF
  '7' // JPEG
]
