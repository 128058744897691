import { sanitize } from '../routes'

export enum MsOfficeApp {
  word = 'word',
  powerPoint = 'powerpoint',
  excel = 'excel',
  visio = 'visio',
  access = 'access',
  project = 'project',
  publisher = 'publisher',
  sharePointDesigner = 'spd',
  infoPath = 'infopath'
}

export enum MsOfficeOpenMode {
  view = 'ofv',
  edit = 'ofe',
  newFromTemplate = 'nft'
}

const argumentNames = {
  uri: 'u',
  saveFolder: 's'
} as const

interface GetWebDAVUriParams {
  app: MsOfficeApp
  fileUri: string
  mode?: MsOfficeOpenMode
}

export function getWebDAVUri ({
  app, fileUri, mode = MsOfficeOpenMode.edit
}: GetWebDAVUriParams): string {
  const protocol = `ms-${app}:${mode}`
  const uriArguments = {
    [argumentNames.uri]: sanitize(fileUri)
  }

  const uriArgumentsString = Object.entries(uriArguments)
    .map(([key, value]) => `${key}|${value}`)
    .join('|')

  return `${protocol}|${uriArgumentsString}`
}
